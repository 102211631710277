import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseURL, {
  DataBaseName,
  LazyProductEndPoint,
  ProductQuestionsEndPoint,
  ProductsEndPoint,
} from "../../api/GlobalData";
import Cookies from "js-cookie";

const jwt = Cookies.get("Token");

export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers) => {
      if (jwt) {
        headers.set("Authorization", `Bearer ${jwt}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Product"],
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: (params) => {
        const {
          brandId,
          minAvg,
          maxAvg,
          sort,
          taxPriceMin,
          taxPriceMax,
          ...rest
        } = params;

        // Construct URL parameters
        const filteredParams = Object.fromEntries(
          Object.entries(rest).filter(
            ([_, value]) => value !== undefined && value !== null
          )
        );
        const searchParams = new URLSearchParams(filteredParams);

        // Append brandId as brandId[0]=...&brandId[1]=...
        if (Array.isArray(brandId) && brandId.length > 0) {
          searchParams.append(`brandId`, brandId);
        }
        // Append minAvg and maxAvg
        if (minAvg !== undefined && minAvg !== null) {
          searchParams.append("minAvg", minAvg);
        }

        if (maxAvg !== undefined && maxAvg !== null) {
          searchParams.append("maxAvg", maxAvg);
        }

        // Append minAvg and maxAvg
        if (taxPriceMin !== undefined && taxPriceMin !== null) {
          searchParams.append("taxPriceMin", taxPriceMin);
        }

        if (taxPriceMax !== undefined && taxPriceMax !== null) {
          searchParams.append("taxPriceMax", taxPriceMax);
        }

        // Append sort parameters
        switch (sort) {
          case "rating":
            searchParams.append("ratingsAverage", "-1");
            break;
          case "price":
            searchParams.append("taxPrice", "1");
            break;
          case "price-desc":
            searchParams.append("taxPrice", "-1");
            break;
          case "date":
            searchParams.append("date", "1");
            break;
          default:
            break;
        }

        // Return configuration for the API request
        return {
          url: `${LazyProductEndPoint}?databaseName=${DataBaseName}&${searchParams.toString()}`, // URL with query params
        };
      },
      providesTags: ["Product"],
    }),
    getFeaturedProducts: builder.query({
      query: () =>
        `${ProductsEndPoint}/featureProduct?databaseName=${DataBaseName}`,
      providesTags: ["Product"],
    }),
    getSponsoredProducts: builder.query({
      query: () =>
        `${ProductsEndPoint}/sponsorProduct?databaseName=${DataBaseName}`,
      providesTags: ["Product"],
    }),
    getProductQuestions: builder.query({
      query: (id) =>
        `${ProductQuestionsEndPoint}/productQuestions/${id}?databaseName=${DataBaseName}&sort=desc`,
      providesTags: ["Product"],
    }),
    addProductQuestion: builder.mutation({
      query: (data) => ({
        url: `${ProductQuestionsEndPoint}?databaseName=${DataBaseName}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Product"],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetProductsQuery,
  useGetFeaturedProductsQuery,
  useGetSponsoredProductsQuery,
  useGetProductQuestionsQuery,
  useAddProductQuestionMutation,
} = productsApi;
