import { useMemo } from "react";
import DOMPurify from "dompurify";

const useSanitizedHook = (lang, data) => {
  // Memoize the sanitized descriptions to avoid unnecessary re-sanitization
  const sanitizedShortDescription = useMemo(() => {
    const description =
      lang === "ar"
        ? data?.data?.shortDescriptionAR
        : lang === "tr"
        ? data?.data?.shortDescriptionTR
        : data?.data?.shortDescription;

    return DOMPurify.sanitize(description, {
      allowedTags: [], // No tags allowed
      allowedAttributes: {}, // No attributes allowed
    });
  }, [lang, data]);

  const sanitizedLongDescription = useMemo(() => {
    const description =
      lang === "ar"
        ? data?.data?.descriptionAR
        : lang === "tr"
        ? data?.data?.descriptionTR
        : data?.data?.description;

    return DOMPurify.sanitize(description, {
      allowedTags: [], // No tags allowed
      allowedAttributes: {}, // No attributes allowed
    });
  }, [lang, data]);
  return { sanitizedShortDescription, sanitizedLongDescription };
};

export default useSanitizedHook;
