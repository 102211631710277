import React from "react";
import { toast } from "react-toastify";
import { Plus } from "react-bootstrap-icons";
import { Modal } from "react-bootstrap";
import TransHook from "../../hooks/translation/trans-hook";
import AddressCard from "./AddressCard";
import Shippings from "../../pages/Account/Shippings";
import CartAddressesHook from "../../hooks/cart/CartAddressesHook";
import { useNavigate } from "react-router-dom";

const AddressDetails = ({ cartId, goBackToCart, proceedToCheckout }) => {
  const { t } = TransHook();
  const navigate = useNavigate();

  const {
    screen,
    addressGet,
    activeShipIndex,
    activeBilIndex,
    addingAddress,
    alias,
    fullName,
    city,
    details,
    town,
    phone,
    activeCart,
    differentBilling,
    showEdit,
    cur,
    additionRate,
    onlinePrice,
    editAddressId,
    userData,
    savedAddress,
    setShowEdit,
    handleEditAddress,
    handleAliasChange,
    handleFullNameChange,
    handleCityChange,
    handleDetailsChange,
    handleTownChange,
    handlePhoneChange,
    save,
    cancel,
    setDifferentBilling,
    setAddingAddress,
    selectShipAddress,
    selectBilAddress,
  } = CartAddressesHook(cartId);

  const lang = localStorage.getItem("language");

  return (
    <div
      className={screen === "large" ? "d-flex" : ""}
      style={{ justifyContent: "space-around" }}
    >
      {/* Edit modal */}
      <Modal show={showEdit} onHide={() => setShowEdit(false)}>
        <Modal.Header>
          <Modal.Title>{t("addressUpdate")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Shippings
            active={"shipping"}
            onCancel={() => setShowEdit(false)}
            editAddressId={editAddressId}
          />
        </Modal.Body>
      </Modal>

      {/* Address cards */}
      <div className={screen === "large" ? "col-lg-7" : ""}>
        <h2 className="step-title">{t("shippingDetails")}</h2>

        {userData && (
          <div className="d-flex flex-wrap Adresses">
            {addressGet && addressGet?.data?.length > 0 ? (
              addressGet?.data?.map((item, i) => {
                const isActive = activeShipIndex === i;
                return (
                  <div
                    className={`col-md-6 mb-3`}
                    key={i}
                    onClick={() => selectShipAddress(i, item)}
                  >
                    <AddressCard
                      alias={item.alias}
                      name={item.name}
                      phone={item.phone}
                      details={item.details}
                      city={item.city}
                      town={item.town}
                      isActive={isActive}
                      isCommercial={item.isCommercial}
                      taxNo={item.taxNo}
                      taxAdministration={item.taxAdministration}
                      companyName={item.companyName}
                      id={item?._id}
                      onEdit={handleEditAddress}
                    />
                  </div>
                );
              })
            ) : (
              <h3>{t("noAddresses")}</h3>
            )}
          </div>
        )}

        {!userData && (
          <AddressCard
            alias={savedAddress?.alias}
            name={savedAddress?.name}
            phone={savedAddress?.phone}
            details={savedAddress?.details}
            city={savedAddress?.city}
            town={savedAddress?.town}
            canDelete={false}
            canEdit={false}
            isActive={true}
          />
        )}

        {differentBilling && (
          <>
            <div className="form-group">
              <div
                className="custom-control custom-checkbox mt-0"
                onClick={() => setDifferentBilling(!differentBilling)}
              >
                <input
                  type="checkbox"
                  checked={differentBilling}
                  className="custom-control-input"
                  id="different-shipping"
                />
                <label className="custom-control-label">
                  {t("differentBilling")}
                </label>
              </div>
            </div>

            <div
              className="d-flex flex-wrap"
              style={{ justifyContent: "unset" }}
            >
              {addressGet && addressGet?.data?.length > 0 ? (
                addressGet?.data?.map((item, i) => {
                  const isActive = activeBilIndex === i;
                  return (
                    <div
                      className={`col-md-6 mb-3`}
                      key={i}
                      onClick={() => selectBilAddress(i, item)}
                    >
                      <AddressCard
                        alias={item?.alias}
                        name={item?.name}
                        phone={item?.phone}
                        details={item?.details}
                        city={item?.city}
                        town={item?.town}
                        isActive={isActive}
                        id={item?._id}
                        onEdit={handleEditAddress}
                      />
                    </div>
                  );
                })
              ) : (
                <h3>{t("differentBilling")}</h3>
              )}
            </div>
          </>
        )}

        <div
          className="address-card col-md-12 text-center mb-4"
          onClick={() => setAddingAddress(!addingAddress)}
        >
          <Plus size={50} />
          <h3>{userData ? t("addNewAddress") : t("addAddress")}</h3>
        </div>

        {/* Address form START */}
        <form
          action="#"
          id="checkout-form"
          className={addingAddress ? "" : "collapse"}
        >
          <div className="address account-content mt-0 pt-2">
            <h4 className="title">{t("addNewAddress")}</h4>

            <form className="mb-2" action="#">
              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label>
                    {t("addressTitleHomeWork")}{" "}
                    <span className="required">*</span>
                    <span style={{ fontSize: "12" }}>
                      ({t("atLeast")} 2 {t("characters")})
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("home")}
                    required
                    value={alias}
                    onChange={handleAliasChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      {t("fullName")} <span className="required">*</span>
                      <span style={{ fontSize: "12" }}>
                        ({t("atLeast")} 5 {t("characters")})
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={fullName}
                      onChange={handleFullNameChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <label>
                    {t("townCity")} <span className="required">*</span>
                    <span style={{ fontSize: "12" }}>
                      ({t("atLeast")} 2 {t("characters")})
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={city}
                    onChange={handleCityChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>
                    {t("address")} <span className="required">*</span>
                    <span style={{ fontSize: "12" }}>
                      ({t("atLeast")} 15 {t("characters")})
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={details}
                    onChange={handleDetailsChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <label>
                    {t("town")} <span className="required">*</span>
                    <span style={{ fontSize: "12" }}>
                      ({t("atLeast")} 3 {t("characters")})
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={town}
                    onChange={handleTownChange}
                  />
                </div>

                <div className="form-group col-md-6 mb-3">
                  <label>
                    {t("phoneNumber")} <span className="required">*</span>
                    <span style={{ fontSize: "12" }}>
                      ({t("atLeast")} 6 {t("characters")})
                    </span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    required
                    value={phone}
                    onChange={handlePhoneChange}
                  />
                </div>
              </div>

              <div className="form-footer mb-0">
                <div className="form-footer-right">
                  <span
                    className="btn btn-dark py-4"
                    onClick={() => {
                      if (
                        alias.length > 1 &&
                        fullName.length > 4 &&
                        city.length > 1 &&
                        details.length > 10 &&
                        town.length > 2 &&
                        phone.length > 5
                      ) {
                        save();
                      } else {
                        toast.error(t("short"));
                      }
                    }}
                  >
                    {t("save")}
                  </span>
                  <button
                    type="button"
                    className="btn btn-dark py-4"
                    onClick={() => cancel()}
                  >
                    {t("cancel")}
                  </button>
                </div>
              </div>
            </form>
          </div>
          {/* Contact END */}
        </form>
        {/* Address form END */}
      </div>

      {/* Order summary */}
      <div className={screen === "large" ? "col-lg-4" : ""}>
        <div className="order-summary">
          <h3>{t("yourOrder")}</h3>

          <table className="table table-mini-cart">
            <thead>
              <tr>
                <td style={{ border: "none" }} colSpan="2">
                  {t("product")}
                </td>
              </tr>
            </thead>
            <tbody>
              {activeCart?.cartItems?.map((item, i) => {
                const prodName =
                  lang === "en"
                    ? item?.product?.name
                    : lang === "tr"
                    ? item?.product?.nameTR
                    : item?.product?.nameAR;

                return (
                  <tr key={i}>
                    <td className="product-col">
                      <h3 className="product-title">
                        {prodName}&nbsp;×
                        <span className="product-qty">{item.quantity}</span>
                      </h3>
                    </td>

                    <td className="price-col">
                      <span>
                        {cur.currencyCode}
                        {(
                          item.taxPrice *
                          (1 + additionRate / 100) *
                          item.quantity
                        ).toFixed(2)}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr className="order-total">
                <td>
                  <h4>{t("total")}</h4>
                </td>
                <td colSpan={2}>
                  <b className="total-price">
                    <span>
                      {cur.currencyCode}
                      {parseFloat(onlinePrice).toFixed(2)}
                    </span>
                  </b>
                </td>
              </tr>
            </tfoot>
          </table>

          <div className="d-flex">
            <h4
              className="btn btn-dark btn-place-order mx-2"
              onClick={goBackToCart}
            >
              {t("backToCart")}
            </h4>
            <h4
              className="btn btn-dark btn-place-order mx-2"
              onClick={() => {
                if (
                  ((activeShipIndex === null ||
                    (differentBilling && activeBilIndex === null)) &&
                    userData) ||
                  (savedAddress?.alias?.length < 2 &&
                    savedAddress?.name?.length < 4 &&
                    savedAddress?.city?.length < 2 &&
                    savedAddress?.details?.length < 10 &&
                    savedAddress?.town?.length < 4 &&
                    savedAddress?.phone?.length < 5)
                ) {
                  toast.error(t("selectAddress"));
                } else {
                  navigate("/checkout");
                  proceedToCheckout(cartId);
                }
              }}
            >
              {t("proceedToCheckout")}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressDetails;
