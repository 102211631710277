import React, { useEffect, useState } from "react";
import { useGetOrderInvoiceQuery } from "../../RTK/Api/OrderApi";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loaders/Loader";
import FormatTime from "../../hooks/GobalHooks.js/FormatTime";
import TransHook from "../../hooks/translation/trans-hook";

const Invoice = () => {
  const { id } = useParams();
  const { t } = TransHook();

  const [order, setOrder] = useState(null);

  useEffect(() => {
    const storedOrder = sessionStorage.getItem("orderData");

    if (storedOrder) {
      setOrder(JSON.parse(storedOrder));

      // Remove from sessionStorage
      //sessionStorage.removeItem("orderData");
    }
  }, []);

  const { currencyCode } = JSON.parse(Cookies.get("CompanyCurrency"));
  const company = JSON.parse(Cookies.get("CompanyData"));

  const screenWidth = window.innerWidth;
  const colStyle = {
    position: "relative",
    width: screenWidth >= 768 ? "50%" : "100%",
    minHeight: "1px",
    paddingRight: "15px",
    paddingLeft: "15px",
    textAlign: "right",
    maxWidth: screenWidth >= 768 ? "50%" : "100%",
  };

  const {
    data: invoice,
    isLoading: invoiceLoading,
    error: invoiceError,
  } = useGetOrderInvoiceQuery(id);
  if (invoiceError) toast.error(invoiceError);

  if (invoiceLoading) {
    return <Loader />;
  } else {
    return (
      <div
        style={{
          fontSize: "18px",
          marginTop: "3rem",
          width: "100%",
          paddingRight: "4%",
          paddingLeft: "4%",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginRight: "-15px",
            marginLeft: "-15px",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              minHeight: "1px",
              paddingRight: "15px",
              paddingLeft: "15px",
              textAlign: "center",
            }}
          >
            <img
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={company?.companyLogo}
              alt={company?.companyName}
              width={150}
            />
            <h2 style={{ marginTop: "1.5rem" }}>
              {company?.companyName.toUpperCase()}
            </h2>
          </div>
        </div>
        <div
          style={{
            color: "#707070",
            display: "flex",
            flexWrap: "wrap",
            marginRight: "-15px",
            marginLeft: "-15px",
            marginTop: "1.5rem",
          }}
        >
          <div style={colStyle}>
            <p style={{ margin: "0", textAlign: "start" }}>
              {company?.companyName}
            </p>
            <p style={{ margin: "0", textAlign: "start" }}>
              {company?.companyAddress}
            </p>
            <p style={{ margin: "0", textAlign: "start" }}>
              {t("phoneNumber")}: {company?.companyTel}
            </p>
            <p style={{ margin: "0", textAlign: "start" }}>
              {t("email")}: {company?.companyEmail}
            </p>
            <p style={{ margin: "0", textAlign: "start" }}>
              {t("taxNo")}: {company?.companyTax}
            </p>
          </div>
          <div style={colStyle}>
            <p>
              <strong>{t("customer")}:</strong>
            </p>
            <p>
              {order?.shippingAddress?.isCommercial
                ? order?.billingAddress?.companyName
                : order?.billingAddress?.name}
            </p>
            <p>{order?.billingAddress?.email}</p>
            <p>{order?.billingAddress?.phone}</p>
            <p>
              {order?.billingAddress?.details}, {order?.billingAddress?.city}{" "}
            </p>
            {order?.shippingAddress?.isCommercial ? (
              <>
                <h4>Tax</h4>
                <p>
                  {order?.shippingAddress?.taxNo} (
                  {order?.shippingAddress?.taxAdministration})
                </p>
              </>
            ) : (
              order?.billingAddress?.taxAdministration &&
              order?.billingAddress?.taxNumber && (
                <>
                  <h4>Tax</h4>
                  <p>
                    {order?.billingAddress?.taxNumber} (
                    {order?.billingAddress?.taxAdministration})
                  </p>
                </>
              )
            )}

            <div style={{ border: "0 2px 0 0 solid #707070" }}>
              <p>
                {t("invoiceNumber")}: {invoice?.data?.counter}
              </p>
              <p>{FormatTime(invoice?.data?.createdAt)}</p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginRight: "-15px",
            marginLeft: "-15px",
            marginTop: "1.5rem",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              minHeight: "1px",
              paddingRight: "15px",
              paddingLeft: "15px",
              textAlign: "center",
            }}
          >
            <table
              style={{
                border: "1px solid #dee2e6",
                width: "100%",
                marginBottom: "1rem",
                backgroundColor: "transparent",
                borderCollapse: "collapse",
              }}
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t("productService")}</th>
                  <th>{t("note")}</th>
                  <th>{t("amount")}</th>
                  <th>{t("unitPrice")}</th>
                  <th>{t("tax")}</th>
                  <th>{t("total")}</th>
                </tr>
              </thead>
              <tbody>
                {invoice?.data?.invoicesItems?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item?.name}</td>
                      <td>{item?.note}</td>
                      <td>{item?.soldQuantity}</td>
                      <td>
                        {item?.sellingPrice} {currencyCode}
                      </td>
                      <td>{`${item?.tax?.tax} (${item?.taxValue}) ${currencyCode}`}</td>
                      <td>{item?.total}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginRight: "-15px",
            marginLeft: "-15px",
            marginTop: "1.5rem",
          }}
        >
          <div
            style={{
              flex: "0 0 66.666667%",
              maxWidth: "66.666667%",
            }}
          ></div>
          <div
            style={{
              position: "relative",
              width: "100%",
              minHeight: "1px",
              paddingRight: "15px",
              paddingLeft: "15px",
              flex: "0 0 33.333333%",
              maxWidth: "33.333333%",
            }}
          >
            <table
              style={{
                border: "1px solid #dee2e6",
                width: "100%",
                marginBottom: "1rem",
                backgroundColor: "transparent",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ border: "none" }}>{t("subtotal")}</td>
                  <td style={{ border: "none" }}>
                    {invoice?.data?.invoiceSubTotal} {currencyCode}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "none" }}>{t("totalTax")}</td>
                  <td style={{ border: "none" }}>
                    {invoice?.data?.invoiceTax} {currencyCode}
                  </td>
                </tr>
                <tr>
                  <td style={{ borderTop: "1px solid #ccc" }}>
                    <b>{t("grandTotal")}</b>
                  </td>
                  <td style={{ borderTop: "1px solid #ccc" }}>
                    <b>
                      {invoice?.data?.invoiceGrandTotal} {currencyCode}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
};

export default Invoice;
