import React, { useEffect, useState } from "react";
import Orders from "./Orders";
import AccountDetails from "./AccountDetails";
import Addresses from "./Addresses";
import Wishlists from "./Wishlists";
import Navbar from "./../../components/utility/navbar/navbar";
import Footer from "../../components/utility/Footer/Footer";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import TransHook from "../../hooks/translation/trans-hook";
import { Helmet } from "react-helmet";

const MyDashboard = ({ activetab }) => {
  const navigate = useNavigate();
  const { t } = TransHook();
  const [activeTab, setActiveTab] = useState(activetab);
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    navigate(`/${tabId}`);
  };
  const signOut = () => {
    Cookies.remove("Token");
    Cookies.remove("userData");
    sessionStorage.removeItem("AnonymousToken");
    // localStorage.removeItem("userCart");
    localStorage.removeItem("shipAddress");
    localStorage.removeItem("billAddress");
    localStorage.removeItem("order");
    localStorage.removeItem("cart");
    navigate("/");
  };

  useEffect(() => {
    if (!Cookies.get("userData")) {
      navigate("/login");
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("myAccount")} - Smart E-commerce</title>
      </Helmet>
      <Navbar />
      <div className="page-wrapper">
        <main className="main" id="mainContainer">
          <div className="page-header">
            <div className="container d-flex flex-column align-items-center mt-4">
              {/* <nav aria-label="breadcrumb" className="breadcrumb-nav">
                <div className="container">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="demo4.html">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="category.html">Shop</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {t("myAccount")}
                    </li>
                  </ol>
                </div>
              </nav> */}
              <h1>{t("myAccount")}</h1>
            </div>
          </div>

          <div className="container account-container custom-account-container">
            <div className="row">
              {/* Sidebar START */}
              <div className="sidebar widget widget-dashboard mb-lg-0 mb-3 col-lg-3 order-0">
                <h2 className="text-uppercase">{t("myAccount")}</h2>
                <ul
                  className="nav nav-tabs list flex-column mb-0"
                  role="tablist"
                >
                  <li className="nav-item">
                    <span
                      className={`nav-link ${
                        activeTab === "account-details" ? "active" : ""
                      } cursor-pointer`}
                      id="edit-tab"
                      data-toggle="tab"
                      onClick={() => handleTabClick("account-details")}
                      role="tab"
                      aria-controls="edit"
                      aria-selected="false"
                    >
                      {t("accountDetails")}
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={`nav-link ${
                        activeTab === "my-orders" ? "active" : ""
                      } cursor-pointer`}
                      id="order-tab"
                      data-toggle="tab"
                      role="tab"
                      onClick={() => handleTabClick("my-orders")}
                      aria-controls="my-orders"
                      aria-selected="true"
                    >
                      {t("orders")}
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={`nav-link ${
                        activeTab === "address" ? "active" : ""
                      } cursor-pointer`}
                      id="address-tab"
                      data-toggle="tab"
                      onClick={() => handleTabClick("my-addresses")}
                      role="tab"
                      aria-controls="address"
                      aria-selected="false"
                    >
                      {t("address")}
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={`nav-link ${
                        activeTab === "my-wishlist" ? "active" : ""
                      } cursor-pointer`}
                      id="wishlist-tab"
                      data-toggle="tab"
                      onClick={() => handleTabClick("my-wishlist")}
                      role="tab"
                      aria-controls="my-wishlist"
                      aria-selected="false"
                    >
                      {t("wishlist")}
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className="nav-link cursor-pointer"
                      onClick={() => signOut()}
                    >
                      {t("logout")}
                    </span>
                  </li>
                </ul>
              </div>
              {/* Sidebar END */}
              <div className="col-lg-9 order-lg-last order-1 tab-content">
                <AccountDetails active={activeTab === "account-details"} />

                <Orders active={activeTab === "my-orders"} />

                <Addresses active={activeTab === "my-addresses"} />

                <Wishlists active={activeTab === "my-wishlist"} />
              </div>
            </div>
          </div>

          <div className="mb-5"></div>
        </main>
      </div>
      <Footer />
      <ToastContainer autoClose={2000} />
    </>
  );
};

export default MyDashboard;
