import React, { useEffect, useState } from "react";
import "./navbar.css";
import Cookies from "js-cookie";
import NavBarHook from "../../../hooks/HomePage/NavBarHook";
import { Link, useNavigate } from "react-router-dom";
import { useGetCartInfoQuery } from "../../../RTK/Api/CartApi";
import { useGetWishlistInfoQuery } from "../../../RTK/Api/WishlistApi";
import { toast } from "react-toastify";
import { Cart, Heart, PersonCircle } from "react-bootstrap-icons";
import { useGetParentCategoryInfoQuery } from "../../../RTK/Api/CategoryApi";
import CatSliderNoImg from "./../Slider/Cat_Slider_NoImg";
import SearchBar from "../Search/SearchBar";
import TransHook from "../../../hooks/translation/trans-hook";
import NavbarTop from "./navbar-top";

const NavbarMiddle = () => {
  const navigate = useNavigate();
  const { t } = TransHook();
  // const localCart = localStorage.getItem("userCart");

  const { signedIn, userInfo, LogOut } = NavBarHook();
  const { data: cartInfo, cartError, refetch } = useGetCartInfoQuery();
  if (cartError) toast.error(cartError);
  const { data: wishlistInfo, wishlistError } = useGetWishlistInfoQuery();
  if (wishlistError) toast.error(wishlistError);

  const [ParentCategories, setParentCategories] = useState([]);
  const { data: category, error: categoryError } =
    useGetParentCategoryInfoQuery();
  if (categoryError) toast.error(categoryError);
  useEffect(() => {
    setParentCategories(
      category?.data?.filter((category) => !category.parentCategory)
    );
  }, [category]);

  const emptyData = {
    placeholder: true,
    companyId: "660fab2cb74becb936dee560",
    companyEmail: "abdulrahman.judy0604@gmail.com",
    companyLogo:
      "https://nooncar.com:8003/companyinfo/company-9e4c6278-8994-462a-b49b-02245c748224-1717483800360.png",
    companyName: "kai",
    companyTax: "6081018629",
    companyTel: "05300000000",
    companyAddress:
      "36017 NOLU SK SAIT SAYIN IS MERKEZI No:NO: 30 27090 SEHITKAMIL/ Gaziantep GAZİKENT, Gaziantep",
    companyColors: [],
    facebookUrl: "profile.php?id=100088659776655",
    instagramUrl: "smartinb_solutions/",
    xtwitterUrl: "SmartinbX",
    linkedinUrl: "https://www.linkedin.com/company/smartinb-solutions",
  };
  useEffect(() => {
    const loc = window.location.href;
    if (loc.includes("order-complete")) {
      refetch();
    }
  }, []);

  let comData = Cookies.get("CompanyData")
    ? JSON.parse(Cookies.get("CompanyData"))
    : emptyData;

  const changeLang = (lang) => {
    localStorage.setItem("language", lang);
    window.location.reload();
  };
  useEffect(() => {
    if (!localStorage.getItem("language") == "tr") {
      localStorage.setItem("language", "tr");
      window.reload();
    }
  }, []);
  const lang = localStorage.getItem("language");

  return (
    <div className={`container ${lang === "ar" ? "arabic-text" : ""}`}>
      <NavbarTop />
      <header className="p-3 mb-1 border-bottom">
        <div className="main_navbar ">
          <div className="col-3 navbar_logo">
            <Link to="/">
              <img
                src={comData?.companyLogo}
                alt={comData?.companyName}
                style={{ width: "130px", height: "88px", objectFit: "contain" }}
              />
            </Link>
          </div>
          <SearchBar />
          <div className=" col-3 navbar_links">
            <div className="dropdown text-end ">
              <PersonCircle
                size={24}
                color="#afafaf"
                onClick={() => navigate("/account-details")}
              />
              &nbsp;
              {signedIn ? (
                <span>
                  {userInfo?.name?.length > 0
                    ? userInfo?.name?.substring(0, 10)
                    : userInfo?.email?.substring(0, 10)}
                  ...
                </span>
              ) : (
                <Link to="/login">{t("signInRegister")}</Link>
              )}
              {signedIn && (
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <span
                    className="dropdown-item cursor-pointer"
                    onClick={() => navigate("/account-details")}
                  >
                    {t("myAccount")}
                  </span>
                  <span
                    className="dropdown-item cursor-pointer"
                    onClick={LogOut}
                  >
                    {t("logout")}
                  </span>
                </div>
              )}
            </div>
            <Link
              to="/my-wishlist"
              className=" position-relative"
              title="wishlist"
              style={{ margin: "0 20px" }}
            >
              <Heart size={24} color="#0000003b" />
              {signedIn ? (
                <span className="badge-circle">{wishlistInfo?.results}</span>
              ) : null}
            </Link>
            <div className="dropdown cart-dropdown">
              <Link to="/cart" className="header-icon position-relative">
                <Cart size={24} color="#0000003b" />
                {cartInfo?.numOfCartItems > 0 && (
                  <span className="wishlist-count badge-circle">
                    {cartInfo?.numOfCartItems}
                  </span>
                )}
                {/*cartInfo?.numOfCartItems > 0 ||
                JSON.parse(localCart)?.data?.cartItems?.length > 0 ? (
                  <span className="wishlist-count badge-circle">
                    {cartInfo?.numOfCartItems ||
                      JSON.parse(localCart)?.data?.cartItems?.length}
                  </span>
                ) : null*/}
              </Link>
            </div>

            {/* 
            //Disabled for now because they said they want Turkish only 🤷‍♂️
            <div
              className="dropdown text-end"
              style={{ marginLeft: "20px", marginRight: "20px" }}
            >
              <Link
                to="#"
                className="d-block link-body-emphasis text-decoration-none dropdown-toggle logo"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <Translate size={24} color="#0000003b" />
              </Link>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <span
                  className="dropdown-item cursor-pointer"
                  onClick={() => changeLang("en")}
                >
                  English
                </span>
                <span
                  className="dropdown-item cursor-pointer"
                  onClick={() => changeLang("tr")}
                >
                  Türkçe
                </span>
                <span
                  className="dropdown-item cursor-pointer"
                  onClick={() => changeLang("ar")}
                >
                  عربي
                </span>
              </div>
            </div> */}
          </div>
        </div>
      </header>
      <div>
        <ul className="category_nav">
          {ParentCategories && ParentCategories.length > 0 && (
            <CatSliderNoImg Array={ParentCategories} limit={10} />
          )}
        </ul>
      </div>
    </div>
  );
};

export default NavbarMiddle;
