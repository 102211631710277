import React, { useEffect } from "react";
import AddressesHook from "../../hooks/profile/AddressesHook";
import { toast } from "react-toastify";
import TransHook from "../../hooks/translation/trans-hook";

const Shippings = ({ active, onCancel, editAddressId }) => {
  const {
    handleAliasChange,
    handleFullNameChange,
    handleCityChange,
    handleDetailsChange,
    handleTownChange,
    handlePhoneChange,
    handleIsCommercialChange,
    handleTaxNoChange,
    handleTaxAdministrationChange,
    handleCompanyNameChange,
    alias,
    fullName,
    city,
    details,
    town,
    phone,
    isCommercial,
    taxNo,
    taxAdministration,
    companyName,
    saveAddress,
    updateAddress,
    getOneAddress,
  } = AddressesHook(onCancel);

  const { t } = TransHook();

  useEffect(() => {
    if (editAddressId) {
      getOneAddress(editAddressId)
        .then((data) => {
          handleAliasChange({ target: { value: data?.data?.alias } });
          handleFullNameChange({ target: { value: data?.data?.name } });
          handleCityChange({ target: { value: data?.data?.city } });
          handleDetailsChange({ target: { value: data?.data?.details } });
          handleTownChange({ target: { value: data?.data?.town } });
          handlePhoneChange({ target: { value: data?.data?.phone } });
          handleIsCommercialChange({
            target: { value: data?.data?.isCommercial },
          });
          handleTaxNoChange({ target: { value: data?.data?.taxNo } });
          handleTaxAdministrationChange({
            target: { value: data?.data?.taxAdministration },
          });
          handleCompanyNameChange({
            target: { value: data?.data?.companyName },
          });
        })
        .catch((error) => toast.error(`${t("failedToFetchData")} ${error}`));
    }
  }, [editAddressId]);

  const handleSubmit = () => {
    if (
      alias.trim().length > 2 &&
      fullName.trim().length > 5 &&
      city.trim().length > 2 &&
      details.trim().length > 15 &&
      town.trim().length > 3 &&
      phone.trim().length > 6 &&
      (!isCommercial ||
        (isCommercial &&
          taxNo.trim().length > 5 &&
          taxAdministration.trim().length > 3 &&
          companyName.trim().length > 3))
    ) {
      if (editAddressId) {
        updateAddress(editAddressId, {
          alias,
          name: fullName,
          city,
          details,
          town,
          phone,
          isCommercial,
          taxNo,
          taxAdministration,
          companyName,
        });
      } else {
        saveAddress();
      }
    } else {
      toast.error(t("invalidData"));
    }
  };

  return (
    <div
      className={`tab-pane fade ${active ? "active show" : ""}`}
      id="shipping"
      role="tabpanel"
    >
      <div className="address account-content mt-0 pt-2">
        <h4 className="title mb-3">
          {editAddressId ? t("editAddress") : t("addAddress")}
        </h4>

        <form className="mb-2" action="#">
          <div className="row">
            <div className="form-group mb-3 col-md-6">
              <label>
                {t("addressTitleHomeWork")} <span className="required">*</span>{" "}
                <span style={{ fontSize: "12" }}>
                  ({t("atLeast")} 2 {t("characters")})
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                required
                minLength={2}
                maxLength={20}
                value={alias}
                onChange={handleAliasChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  {t("fullName")} <span className="required">*</span>{" "}
                  <span style={{ fontSize: "12" }}>
                    ({t("atLeast")} 5 {t("characters")})
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  required
                  minLength={5}
                  maxLength={50}
                  value={fullName}
                  onChange={handleFullNameChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <label>
                {t("townCity")} <span className="required">*</span>{" "}
                <span style={{ fontSize: "12" }}>
                  ({t("atLeast")} 2 {t("characters")})
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                required
                minLength={2}
                maxLength={30}
                value={city}
                onChange={handleCityChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>
                {t("address")} <span className="required">*</span>{" "}
                <span style={{ fontSize: "12" }}>
                  ({t("atLeast")} 15 {t("characters")})
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                required
                minLength={15}
                maxLength={80}
                value={details}
                onChange={handleDetailsChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <label>
                {t("town")} <span className="required">*</span>{" "}
                <span style={{ fontSize: "12" }}>
                  ({t("atLeast")} 3 {t("characters")})
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                required
                minLength={4}
                maxLength={10}
                value={town}
                onChange={handleTownChange}
              />
            </div>

            <div className="form-group col-md-6 mb-3">
              <label>
                {t("phoneNumber")} <span className="required">*</span>{" "}
                <span style={{ fontSize: "12" }}>
                  ({t("atLeast")} 6 {t("characters")})
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                required
                value={phone}
                onChange={handlePhoneChange}
                onInput={(e) => {
                  if (e.target.value.length > 15) {
                    e.target.value = e.target.value.slice(0, 15);
                  }
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  checked={isCommercial}
                  type="checkbox"
                  className="mx-2"
                  onChange={handleIsCommercialChange}
                />
                <label>
                  {t("isCommercial")} <span className="required">*</span>
                </label>
              </div>
            </div>
          </div>

          {isCommercial && (
            <>
              <div className="row">
                <div className="form-group col-md-6">
                  <label>
                    {t("taxNo")} <span className="required">*</span>{" "}
                    <span style={{ fontSize: "12" }}>
                      ({t("atLeast")} 5 {t("characters")})
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    minLength={5}
                    maxLength={15}
                    value={taxNo}
                    onChange={handleTaxNoChange}
                  />
                </div>

                <div className="form-group col-md-6 mb-3">
                  <label>
                    {t("taxAdministration")} <span className="required">*</span>{" "}
                    <span style={{ fontSize: "12" }}>
                      ({t("atLeast")} 3 {t("characters")})
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={taxAdministration}
                    onChange={handleTaxAdministrationChange}
                    onInput={(e) => {
                      if (e.target.value.length > 15) {
                        e.target.value = e.target.value.slice(0, 15);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label>
                    {t("companyName")} <span className="required">*</span>{" "}
                    <span style={{ fontSize: "12" }}>
                      ({t("atLeast")} 3 {t("characters")})
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    minLength={3}
                    value={companyName}
                    onChange={handleCompanyNameChange}
                  />
                </div>
              </div>
            </>
          )}

          <div className="form-footer mb-0">
            <div className="form-footer-right">
              <span className="btn btn-dark py-4 mr-2" onClick={handleSubmit}>
                {editAddressId ? t("update") : t("save")}
              </span>
              <button
                type="button"
                className="btn btn-danger py-4 ml-2"
                onClick={onCancel}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Shippings;
