import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useAddCartItemMutation,
  useGetCartInfoQuery,
} from "../../RTK/Api/CartApi";
import { useAddWishlistItemMutation } from "../../RTK/Api/WishlistApi";
import TransHook from "./../translation/trans-hook";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import calculateAndFormatPrice from "../GobalHooks.js/FormatPrice";

const ProductCardHook = () => {
  const { t } = TransHook();
  const [addCartItem, { error: addItemError }] = useAddCartItemMutation();
  const { refetch: refetchCart, data: activeCart } = useGetCartInfoQuery();
  if (addItemError) toast.error(t("failedToAddToCart"));

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const data = Cookies.get("userData");
    if (data) {
      setUserData(JSON.parse(data));
    }
  }, []);

  const navigate = useNavigate();
  const handleCart = async (product) => {
    const currentCartItem = activeCart?.data?.cartItems?.length
      ? activeCart.data.cartItems.find((item) => item.qr === product.qr)
      : null;
    const totalProductQuantity = product.stocks.reduce(
      (total, stock) => total + stock.productQuantity,
      0
    );
    const currentQuantity = currentCartItem?.quantity || 0;
    const availableStock = totalProductQuantity;

    if (currentQuantity >= availableStock) {
      toast.error(t("cantAddMore"));
      return;
    }

    const price = calculateAndFormatPrice(
      product.ecommercePrice,
      product.ecommercePriceAftereDiscount,
      Array.isArray(product?.currency)
        ? product.currency[0]?.exchangeRate
        : product?.currency?.exchangeRate
    );

    const cartItem = {
      qr: product?.qr,
      taxPrice: price,
      quantity: 1,
      tax: {
        taxId: product.tax[0]._id,
        taxRate: product.tax[0].tax.toString(),
      },
      profitRatio: product.profitRatio,
      user: userData?._id,
    };

    await addCartItem(cartItem)
      .unwrap()
      .then((response) => {
        const userCart = {
          status: response.status,
          numOfCartItems: response.numOfCartItems,
          data: {
            _id: response.data._id,
            cartItems: response.data.cartItems,
            customar: response.data.customar,
            createdAt: response.data.createdAt,
            updatedAt: response.data.updatedAt,
            totalCartPrice: response.data.totalCartPrice,
          },
        };

        toast.success(t("addedToCart"));
      })
      .catch((error) => {
        console.error("Failed to add to cart:", error);
        toast.error(t("failedToAddToCart"));
      });
  };

  const BuyNow = async (product) => {
    const storedCart = JSON.parse(localStorage.getItem("userCart"));
    const currentCartItems = storedCart?.data?.cartItems || [];

    const currentCartItem = currentCartItems.find(
      (item) => item.qr === product.qr
    );
    const totalQuantityInCart = currentCartItem ? currentCartItem.quantity : 0;

    const availableStock = product.stocks.reduce(
      (total, stock) => total + stock.productQuantity,
      0
    );

    if (totalQuantityInCart >= availableStock) {
      toast.error(t("cantAddMore"));
      return;
    }

    const price = calculateAndFormatPrice(
      product.ecommercePrice,
      product.ecommercePriceAftereDiscount,
      Array.isArray(product?.currency)
        ? product.currency[0]?.exchangeRate
        : product?.currency?.exchangeRate
    );

    const cartData = {
      qr: product?.qr || "",
      quantity: 1,
      taxPrice: price,
      buyingPrice: product?.buyingprice,
      taxRate: product?.tax[0]?.tax,
      name: product?.name,
      price: product?.price,
      tax: {
        taxId: product.tax[0]._id,
        taxRate: product.tax[0].tax.toString(),
      },
      profitRatio: product.profitRatio,
      user: userData?._id,
    };

    await addCartItem(cartData)
      .unwrap()
      .then((response) => {
        const userCart = {
          status: response.status,
          numOfCartItems: response.numOfCartItems,
          data: {
            _id: response.data._id,
            cartItems: response.data.cartItems,
            customar: response.data.customar,
            createdAt: response.data.createdAt,
            updatedAt: response.data.updatedAt,
            totalCartPrice: response.data.totalCartPrice,
          },
        };

        localStorage.setItem("userCart", JSON.stringify(userCart));
        toast.success(t("addedToCart"));
        refetchCart();
        navigate("/cart");
      })
      .catch((error) => {
        console.error("Failed to add to cart:", error);
        toast.error(t("failedToAddToCart"));
      });
  };

  const [addWishlistItem, { error: addError }] = useAddWishlistItemMutation();
  if (addError) toast.error(addError);

  const handleAddItem = async (itemId) => {
    if (userData) {
      try {
        await addWishlistItem({ productId: itemId })
          .unwrap()
          .then(() => toast.success(t("addedToWishlist")));
      } catch (error) {
        toast.error(error);
      }
    } else {
      toast.warn(t("pleaseLogin"));
    }
  };

  const screen = localStorage.getItem("screen") || "large";

  return { screen, handleCart, BuyNow, handleAddItem };
};

export default ProductCardHook;
