import { useEffect, useState } from "react";
import {
  useAddProductQuestionMutation,
  useGetProductQuestionsQuery,
} from "../../../RTK/Api/ProductApi";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import TransHook from "../../translation/trans-hook";

const ProductQuestionsHook = (productId) => {
  const { t } = TransHook();

  const {
    data: questions,
    error,
    isLoading,
  } = useGetProductQuestionsQuery(productId);

  useEffect(() => {
    if (error) toast.error(error);
  }, [error]);

  const [question, setQuestion] = useState("");
  const [customerId, setCustomerId] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const userDataString = Cookies.get("userData");
    if (userDataString) {
      try {
        const userData = JSON.parse(userDataString);
        if (userData?._id) setCustomerId(userData._id);
      } catch (err) {
        console.error("Failed to parse user data:", err);
      }
    }
  }, []);

  const [
    addProductQuestion,
    { error: addQuestionError, isLoading: addQuestionLoading },
  ] = useAddProductQuestionMutation();

  useEffect(() => {
    if (addQuestionError) toast.error(addQuestionError);
  }, [addQuestionError]);

  const handleAddQuestion = async () => {
    if (question.trim().length < 10) return toast.error(t("questionLength"));
    if (!customerId) return toast.error(t("pleaseLogin"));

    try {
      await addProductQuestion({
        question,
        customar: customerId,
        product: productId,
      }).unwrap();

      toast.success(t("questionSent"));
      setQuestion("");
      setOpen(false);
    } catch (error) {
      toast.error(t("failedToSendQuestion"));
    }
  };

  return {
    questions,
    isLoading,
    addQuestionLoading,
    open,
    question,
    handleAddQuestion,
    setQuestion,
    setOpen,
  };
};

export default ProductQuestionsHook;
