import { useEffect, useState } from "react";
import {
  useAddCartItemMutation,
  useDecreaseCartItemMutation,
  useGetCartInfoQuery,
  useRemoveCartItemMutation,
} from "../../RTK/Api/CartApi";
import { toast } from "react-toastify";
import TransHook from "./../translation/trans-hook";
import Cookies from "js-cookie";

const CartHook = (proceedToAddress) => {
  const [cartTotal, setCartTotal] = useState();
  const { t } = TransHook();

  const [addCartItem, { isLoading: isAddLoading, error: addItemError }] =
    useAddCartItemMutation();
  if (addItemError) toast.error(t("failedToAddToCart"));

  const {
    data: cart,
    error: cartError,
    isLoading: isCartLoading,
    refetch,
  } = useGetCartInfoQuery();
  if (cartError) toast.error(cartError);

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const data = Cookies.get("userData");
    if (data) {
      setUserData(JSON.parse(data));
    }
    window.scrollTo(0, 0);
  }, []);

  const handleAddToCart = async (product) => {
    const { qr, taxPrice, product: prod } = product;

    const availableStock =
      product?.product?.stocks?.reduce(
        (total, stock) => total + stock.productQuantity,
        0
      ) || 0;

    if (!availableStock) {
      toast.error(t("productOutOfStock"));
      return;
    }

    const cartItem = {
      qr,
      taxPrice,
      quantity: 1,
      ecommercePriceMainCurrency: prod?.ecommercePriceMainCurrency,
      tax: {
        taxId: product.product.tax._id,
        taxRate: product.product.tax.tax,
      },
      profitRatio: product.product.profitRatio,
      user: userData?._id,
    };

    await addCartItem(cartItem)
      .unwrap()
      .then(() => {
        refetch();
        toast.success(t("addedToCart"));
      })
      .catch(() => toast.error(t("failedToAddToCart")));
  };

  const [
    decreaseCartItem,
    { isLoading: isDecLoading, error: decreaseItemError },
  ] = useDecreaseCartItemMutation();
  if (decreaseItemError) toast.error(t("cantDecrease"));

  const handleDecreaseCart = async (product) => {
    if (product.quantity <= 1) {
      toast.error(t("minimumQuantity"));
      return;
    }

    await decreaseCartItem({
      itemId: product.product._id,
      quantity: product.quantity - 1,
    })
      .unwrap()
      .then(() => {
        refetch();
        toast.success(t("amountDecreased"));
      })
      .catch((error) => toast.error(error));
  };

  const [removeCartItem, { error: removeError }] = useRemoveCartItemMutation();
  if (removeError) toast.error(removeError);

  const handleRemoveItem = async (itemId) => {
    try {
      await removeCartItem(itemId)
        .unwrap()
        .then(() => {
          refetch();
          toast.success(t("itemRemoved"));
        });
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (!isCartLoading) {
      const totalPrice = cart?.data?.totalCartPrice || 0;
      setCartTotal(totalPrice);
    }
  }, [isCartLoading, cart]);

  const submitCart = () => {
    if (cart?.data?._id !== null && cart?.data?._id !== undefined) {
      proceedToAddress(cart?.data?._id);
      const cartString = JSON.stringify(cart?.data);
      localStorage.setItem("cart", cartString);
    }
  };

  return {
    isCartLoading,
    isAddLoading,
    isDecLoading,
    cart,
    cart,
    cartTotal,
    handleAddToCart,
    handleDecreaseCart,
    handleRemoveItem,
    submitCart,
  };
};

export default CartHook;
