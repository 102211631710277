import PropTypes from "prop-types";

const FormatTime = (time) => {
  // Parse the date string

  const date = new Date(time);

  // Format the date and time
  const formattedDate = date
    .toLocaleDateString("en-GB")
    .split("/")
    .reverse()
    .join("-");
  const formattedTime = date.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });

  // Return the formatted date and time as a string
  return `${formattedDate} ${formattedTime}`;
};

// Prop validation
FormatTime.propTypes = {
  time: PropTypes.string.isRequired,
};
export default FormatTime;
