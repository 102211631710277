import usePostData from "../../hooks/api/usePostData";
import { SignUpEndPoint } from "../../api/GlobalData";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useLogInMutation } from "../../RTK/Api/AuthApi";
import GetCompanyInfo from "../company/getCompanyInfo";
import { toast } from "react-toastify";
import { useGetThirdPartyAuthQuery } from "../../RTK/Api/ThirdPartyAuthApi";

const AuthHook = () => {
  const navigate = useNavigate();
  let companyLogo = "";
  const [cookiesSet, setCookiesSet] = useState(false);
  const { companyData } = GetCompanyInfo();

  // Get logo from cookies
  if (companyData) {
    companyLogo = companyData?.companyLogo;
  }

  const { data: thirdPartyAuth } = useGetThirdPartyAuthQuery();
  const [googleAuth, setGoogleAuth] = useState(false);
  const [facebookAuth, setFacebookAuth] = useState(false);
  const [facebookAppId, setFacebookAppId] = useState("");
  const [googleClientId, setGoogleClientId] = useState("");

  useEffect(() => {
    if (thirdPartyAuth?.data?.length > 0) {
      setGoogleAuth(
        thirdPartyAuth.data[0].googleClientID !== "" &&
          thirdPartyAuth.data[0].googleClientSecret !== ""
      );
      setFacebookAuth(thirdPartyAuth.data[0].facebookAppID !== "");
      setFacebookAppId(thirdPartyAuth.data[0].facebookAppID);
      setGoogleClientId(thirdPartyAuth.data[0].googleClientID);
    }
  }, [thirdPartyAuth]);

  // SignIn functionality
  const [LogInformData, setLogInFormData] = useState({});
  const [
    logIn,
    { data: logInData, isLoading: logInIsLoading, error: logInError },
  ] = useLogInMutation();
  const handleLogIn = async () => {
    try {
      await logIn(LogInformData);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  // Store token and user info
  useEffect(() => {
    if (logInData) {
      Cookies.set("Token", logInData.token, { expires: 7 });
      const userData = logInData?.data;
      const userDataString = JSON.stringify(userData);
      Cookies.set("userData", userDataString, { expires: 7 });
      sessionStorage.removeItem("AnonymousToken");
      // localStorage.removeItem("userCart");
      localStorage.removeItem("shipAddress");
      localStorage.removeItem("billAddress");
      localStorage.removeItem("order");
      localStorage.removeItem("cart");
      setCookiesSet(true);
    } else if (logInError) {
      console.error(logInError);
      toast.error(logInError);
    }
  }, [logInError, logInData]);

  // SignUp Functionality
  const [SignUpformData, setSignUpFormData] = useState({});
  const {
    mutate: signUpMutate,
    data: signUpData,
    isLoading: signUpIsLoading,
    error: signUpError,
  } = usePostData(SignUpEndPoint);

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      signUpMutate(SignUpformData);
    } catch (error) {
      console.error("Signup failed:", error);
    }
  };

  // Store token and user info
  useEffect(() => {
    if (signUpData) {
      Cookies.set("Token", signUpData.token, { expires: 7 });
      const userData = signUpData?.data;
      const userDataString = JSON.stringify(userData);
      Cookies.set("userData", userDataString, { expires: 7 });
      sessionStorage.removeItem("AnonymousToken");
      setCookiesSet(true);
    } else if (signUpError) {
      console.error(signUpError);
      toast.error(signUpError);
    }
  }, [signUpError, signUpData]);

  // Navigate back only when cookies are set
  useEffect(() => {
    if (cookiesSet) {
      window.history.back();
      setTimeout(() => {
        window.location.reload();
      }, 400);
    }
  }, [cookiesSet, navigate]);

  // Set the LoginData into the state
  const handleLogInChange = (e) => {
    setLogInFormData({ ...LogInformData, [e.target.name]: e.target.value });
  };

  const handleSignUpChange = (e) => {
    setSignUpFormData({ ...SignUpformData, [e.target.name]: e.target.value });
  };

  return {
    handleLogIn,
    handleLogInChange,
    handleSignUp,
    handleSignUpChange,
    logInIsLoading,
    logInError,
    signUpIsLoading,
    signUpError,
    companyLogo,
    googleAuth,
    facebookAuth,
    facebookAppId,
    googleClientId,
  };
};

export default AuthHook;
