import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Dash, Plus, Trash2Fill } from "react-bootstrap-icons";
import { ProductBaseUrl } from "../../api/GlobalData";
import TransHook from "../../hooks/translation/trans-hook";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import GetPaymentMethodsHook from "../../hooks/PaymentMethods/GetPaymentMethodsHook";

const MobileCartItem = ({
  item,
  handleAddToCart,
  handleDecreaseCart,
  handleRemoveItem,
}) => {
  const { t } = TransHook();
  const cur = JSON.parse(Cookies.get("CompanyCurrency"));

  const { paymentMethods } = GetPaymentMethodsHook();
  const [onlinePrice, setOnlinePrice] = useState("");
  useEffect(() => {
    if (paymentMethods) {
      const onlinePaymentMethod = paymentMethods.find(
        (method) => method.name === "onlinePayment"
      );
      const extraCharge = onlinePaymentMethod?.extraCharge || 0;
      const finalPrice =
        parseFloat(item.taxPrice) +
        parseFloat(item.taxPrice) * (extraCharge / 100);
      setOnlinePrice(finalPrice);
    }
  }, [paymentMethods]);

  return (
    <div className="d-flex cart-item-container">
      <div style={{ width: "35%", margin: "10px" }}>
        <Link to={`/product-details/${item?.product?._id}`}>
          <img
            src={ProductBaseUrl + item?.product?.imagesArray[0]?.image}
            alt={item?.name?.split(" ")[0]}
          />
        </Link>
      </div>

      <div
        className="d-block justify-content-center py-4"
        style={{ width: "65%" }}
      >
        <h3 className="ml-4">
          <Link
            to={`/product-details/${item?.product?._id}`}
            className="fw-bold text-dark"
            title={item?.name}
          >
            {item?.name?.length > 30
              ? item?.name.slice(0, 30) + "..."
              : item?.name}
          </Link>
        </h3>
        <p className="ml-4">
          {t("productCode")}: {item?.qr}
        </p>
        <div className="price justify-content-center align-items-center d-flex">
          <Dash
            className={`cursor-pointer ${
              item?.quantity > 1 ? "" : "cursor-not-allowed"
            } `}
            size={25}
            color="#fe9900"
            onClick={
              item?.quantity > 1
                ? () => handleDecreaseCart(item)
                : () => toast.error(t("cantDecrese"))
            }
          />
          <p className="d-flex cart-item-quantity">{item?.quantity}</p>
          <Plus
            className="cursor-pointer"
            size={25}
            onClick={() =>
              item?.maxQuantity > item?.quantity
                ? handleAddToCart(item)
                : toast.error(t("cantAddMore"))
            }
            color="#fe9900"
          />
        </div>
        <div className="Trash" onClick={() => handleRemoveItem(item?._id)}>
          <Trash2Fill size={18} />
        </div>
        <h3 className="text-center mt-2">
          <span style={{ color: "#fe9900" }}>
            {cur.currencyCode}
            {(onlinePrice * item?.quantity).toFixed(2)}
          </span>
          <h5>
            {parseFloat(onlinePrice).toFixed(2)} × {item?.quantity}
          </h5>
        </h3>
      </div>
    </div>
  );
};

export default MobileCartItem;
