import React, { useState } from "react";
import Shippings from "./Shippings";
import AddressCard from "../../components/Cart/AddressCard";
import { GeoAlt } from "react-bootstrap-icons";
import { useGetAddressInfoQuery } from "../../RTK/Api/AddressApi";
import { ToastContainer, toast } from "react-toastify";
import AddressesHook from "../../hooks/profile/AddressesHook";
import TransHook from "../../hooks/translation/trans-hook";

const Addresses = ({ active }) => {
  const { t } = TransHook();
  const [activeTab, setActiveSubTab] = useState("");
  const [editAddressId, setEditAddressId] = useState(null);

  const { getOneAddress, setFormData } = AddressesHook();

  const handleTabClick = (tabId) => {
    setActiveSubTab(tabId === activeTab ? "" : tabId);
  };

  const handleEditAddress = (id) => {
    if (id) {
      getOneAddress(id)
        .then((data) => {
          setFormData(data);
          setEditAddressId(id);
          handleTabClick("shipping");
        })
        .catch((error) => toast.error(`${t("failedToFetchData")} ${error}`));
    }
  };

  const { data: addressGet, error } = useGetAddressInfoQuery();
  if (error) {
    console.error(error);
    toast.error(error);
  }
  const lang = localStorage.getItem("language");

  return (
    <div
      className={`tab-pane fade ${active ? "active show" : ""} ${
        lang === "ar" ? "arabic-text" : ""
      }`}
      id="my-addresses"
      role="tabpanel"
    >
      <h3 className="account-sub-title d-none d-md-block mb-1">
        <GeoAlt className="align-middle mx-3" />
        {t("address")}
      </h3>
      <div className="addresses-content">
        <p className="mb-4 font-14">{t("addressCardsTitle")}</p>
        <div className="d-flex flex-wrap" style={{ justifyContent: "start" }}>
          {addressGet && addressGet?.data?.length > 0 ? (
            addressGet?.data?.map((item, i) => (
              <div className="col-md-4 mb-3" key={i}>
                <AddressCard
                  alias={item?.alias}
                  name={item?.name}
                  phone={item?.phone}
                  details={item?.details}
                  city={item?.city}
                  town={item?.town}
                  isCommercial={item?.isCommercial}
                  taxNo={item?.taxNo}
                  taxAdministration={item?.taxAdministration}
                  companyName={item?.companyName}
                  id={item?._id}
                  onEdit={handleEditAddress}
                />
              </div>
            ))
          ) : (
            <h3>{t("noAddresses")}</h3>
          )}
        </div>

        {activeTab === "" && (
          <div className="row">
            <div className="address col-md-6 mt-5 mt-md-0">
              <span
                onClick={() => handleTabClick("shipping")}
                className="btn btn-default address-action link-to-tab font-14"
              >
                {t("addNewAddress")}
              </span>
            </div>
          </div>
        )}

        {activeTab === "shipping" && (
          <Shippings
            active={"shipping"}
            onCancel={() => setActiveSubTab("")}
            editAddressId={editAddressId}
          />
        )}
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default Addresses;
