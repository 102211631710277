import React, { useEffect, useState } from "react";
import "../CSS/customProducts.css";
import TransHook from "../../../hooks/translation/trans-hook";
import { CartFill } from "react-bootstrap-icons";

const StickyAddToCartBtn = ({ addToCart }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = TransHook();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`sticky-add-to-cart-btn ${isVisible ? "d-block" : "d-none"}`}
      onClick={() => {
        addToCart();
      }}
    >
      <CartFill />
      <span className="px-4">{t("addToCart")}</span>
    </div>
  );
};

export default StickyAddToCartBtn;
