import React from "react";
import "../CSS/customProducts.css";
import Loader from "../../../components/Loaders/Loader";
import { CartFill, Heart, HeartFill } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import ProductDetailSlider from "../../utility/Slider/ProductDetailSlider";
import GetOneProductHook from "../../../hooks/product/getOneProductHook";
import ProductSlider from "../../utility/Slider/Product-Slider";
import Navbar from "../../utility/navbar/navbar";
import Cookies from "js-cookie";
import Footer from "../../utility/Footer/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TransHook from "../../../hooks/translation/trans-hook";
import StarRatings from "react-star-ratings";
import StickyAddToCartBtn from "./StickyAddToCartBtn";
import { Helmet } from "react-helmet";
import ProductQuestionsHook from "../../../hooks/product/questions/getProductQuestionsHook";

const ProductDetails = () => {
  const { id } = useParams();
  const { t } = TransHook();
  const lang = localStorage.getItem("language");
  const screen = localStorage.getItem("screen") || "large";

  const {
    data,
    isLoading,
    activeTab,
    slides,
    top10Sold,
    maxAmount,
    inputValue,
    alternateProducts,
    onlinePrice,
    Pname,
    currObj,
    sanitizedShortDescription,
    sanitizedLongDescription,
    payments,
    price,
    isWishlisted,
    regularPrice,
    calculateFinalPrice,
    openReviews,
    handleAddItem,
    addToCart,
    navigate,
    handleTabClick,
    handleInputChange,
  } = GetOneProductHook(id);

  const companyData = JSON.parse(Cookies.get("CompanyData"));
  const { questions } = ProductQuestionsHook(id);

  if (isLoading) {
    return (
      <>
        <Navbar />
        <Loader />
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <meta name="description" content={data?.metas?.description?.[lang]} />
          <meta name="keywords" content={data?.metas?.keywords?.[lang]} />
          <title>
            {data?.metas?.title?.[lang].toUpperCase() ||
              Pname.substring(0, 20) + "..."}{" "}
            | Smart E-commerce
          </title>
        </Helmet>
        <Navbar />
        <div
          className={`container ${lang === "ar" ? "arabic-text" : ""}`}
          style={{ marginTop: screen === "large" ? "240px" : "150px" }}
        >
          {/* Hero START */}
          <div className="product-single-container product-single-default">
            <div className="row justify-content-around">
              {/* Main slider START */}
              <div className="col-lg-5 col-md-6 product-single-gallery">
                <ProductDetailSlider slides={4} Array={data} />
              </div>

              {/* Content START */}
              <div className="col-lg-6 col-md-6 product-single-details">
                <h1 className="product-title mt-1">
                  {Pname.length > 200 ? Pname.substring(0, 200) + "..." : Pname}
                </h1>

                {/* Ratings START */}
                <div className="ratings-container">
                  <StarRatings
                    rating={data?.ratingsAverage}
                    starRatedColor="#fe9900"
                    starEmptyColor="lightgray"
                    numberOfStars={5}
                    starDimension="15px"
                    starSpacing=".5px"
                  />

                  <span className="rating-link" onClick={() => openReviews()}>
                    ({data?.ratingsQuantity} {t("ratings")})
                  </span>
                </div>

                <hr className="short-divider" />

                {/* Price START */}
                <div className="price-box">
                  <span className="product-price mx-2">
                    {currObj.currencyCode}
                    {parseFloat(onlinePrice).toFixed(2)}
                  </span>
                  {data?.ecommercePriceAftereDiscount > 0 && (
                    <del className="old-price">
                      <span>
                        {currObj.currencyCode}
                        {regularPrice}
                      </span>
                    </del>
                  )}
                </div>
                <div>
                  {payments.map((item, index) => {
                    const finalPrice = calculateFinalPrice(
                      price,
                      item.extraCharge,
                      item.companyRatio
                    );

                    return (
                      <h5 key={index}>
                        {t(item.name)} {t("for")}&nbsp;
                        {currObj.currencyCode}
                        <b>{finalPrice}</b>
                      </h5>
                    );
                  })}
                </div>
                {/* Price END */}

                {/* Description START */}
                <div className="product-desc">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizedShortDescription,
                    }}
                  />
                </div>
                {/* Description END */}

                {/* Add to cart START */}
                <div className="product-action">
                  <div className="price-box product-filtered-price">
                    {data?.ecommercePriceAftereDiscount && (
                      <del className="old-price">
                        <span>{price}</span>
                      </del>
                    )}
                    <span className="product-price">{price}</span>
                  </div>

                  {maxAmount > 0 ? (
                    <>
                      <div className="product-single-qty">
                        <input
                          className="horizontal-quantity form-control"
                          type="number"
                          max={maxAmount}
                          min={0}
                          value={inputValue}
                          onChange={handleInputChange}
                        />
                      </div>

                      <button
                        onClick={() => addToCart(data)}
                        className="btn btn-dark mr-2 addToCartBtn"
                        title={t("addToCart")}
                      >
                        <CartFill />
                        &nbsp;
                        <span>{t("addToCart")}</span>
                      </button>
                      <button
                        onClick={() => handleAddItem(data?._id)}
                        className="btn btn-icon-wish add-wishlist"
                        title={t("addToWishlist")}
                      >
                        {isWishlisted ? (
                          <>
                            <HeartFill />
                          </>
                        ) : (
                          <>
                            <Heart />
                          </>
                        )}
                      </button>
                    </>
                  ) : (
                    <div className="alert alert-danger">
                      {t("itemNotAvailable")}
                    </div>
                  )}
                </div>
                {/* Add to cart END */}

                <hr className="divider mb-0 mt-0" />

                {/* Specifcations Start */}
                {data?.customAttributes && (
                  <div className="product_Spec">
                    <ul className="d-flex">
                      {data?.customAttributes?.map((attr, index) => {
                        return (
                          <li
                            key={index}
                            className={screen === "large" ? "col-2" : "col-3"}
                          >
                            <h6>{attr?.key?.substring(0, 10)}</h6>
                            {attr?.value?.length > 10 ? (
                              <span> {attr?.value?.substring(0, 10)} ...</span>
                            ) : (
                              <span> {attr?.value}</span>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                {/* Specifcations End */}
              </div>
              {/* Content END */}
            </div>
          </div>
          {/* Hero END */}

          {/* Floating add to cart button START */}
          <StickyAddToCartBtn addToCart={() => addToCart(data)} />
          {/* Floating add to cart button END */}

          {/* Info tabs START */}
          <div className="product-single-tabs">
            {/* Tabs START */}
            <ul className="nav nav-tabs" role="tablist">
              {data?.description && (
                <li className="nav-item">
                  <span
                    className={`cursor-pointer nav-link ${
                      activeTab === "product-desc-content" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("product-desc-content")}
                    role="tab"
                    aria-controls="product-desc-content"
                    aria-selected={activeTab === "product-desc-content"}
                  >
                    {t("description")}
                  </span>
                </li>
              )}

              <li className="nav-item">
                <span
                  className={`cursor-pointer nav-link ${
                    activeTab === "product-reviews-content" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("product-reviews-content")}
                  role="tab"
                  aria-controls="product-reviews-content"
                  aria-selected={activeTab === "product-reviews-content"}
                >
                  {t("reviews")} ({data?.ratingsQuantity})
                </span>
              </li>
              {data?.customAttributes && (
                <li className="nav-item ">
                  <span
                    className={`cursor-pointer nav-link ${
                      activeTab === "product-spec-content" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("product-spec-content")}
                    role="tab"
                    aria-controls="product-spec-content"
                    aria-selected={activeTab === "product-spec-content"}
                  >
                    {t("specifications")}
                  </span>
                </li>
              )}
              {questions?.data?.length > 0 && (
                <li className="nav-item ">
                  <span
                    className={`cursor-pointer nav-link ${
                      activeTab === "product-questions-content" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("product-questions-content")}
                    role="tab"
                    aria-controls="product-spec-content"
                    aria-selected={activeTab === "product-questions-content"}
                  >
                    {t("productQuestions")}
                  </span>
                </li>
              )}
            </ul>

            {/* Tabs content START */}
            <div className="tab-content">
              {data?.description && (
                <div
                  className={`tab-pane fade ${
                    activeTab === "product-desc-content" ? "show active" : ""
                  }`}
                  id="product-desc-content"
                  role="tabpanel"
                  aria-labelledby="product-tab-desc"
                >
                  <div className={`product-desc-content`}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizedLongDescription,
                      }}
                    />
                  </div>
                </div>
              )}

              {data?.customAttributes && (
                <div
                  className={`tab-pane fade ${
                    activeTab === "product-spec-content" ? "show active" : ""
                  }`}
                  id="product-spec-content"
                  role="tabpanel"
                  aria-labelledby="product-spec-tags"
                >
                  <ul className="Spec_list">
                    {data?.customAttributes?.map((attr, index) => {
                      return (
                        <li className="col-5" key={index}>
                          <h5>{attr?.key}</h5>
                          <span>{attr?.value}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              {questions?.data?.some((question) => question.approved) && (
                <div
                  className={`tab-pane fade ${
                    activeTab === "product-questions-content"
                      ? "show active"
                      : ""
                  }`}
                  id="product-questions-content"
                  role="tabpanel"
                  aria-labelledby="questions-tab"
                >
                  {questions?.data
                    ?.filter((question) => question.approved)
                    ?.slice(0, 4)
                    .reduce((rows, question, index) => {
                      if (index % 2 === 0) rows.push([]);
                      rows[rows.length - 1].push(question);
                      return rows;
                    }, [])
                    .map((row, rowIndex) => (
                      <div
                        key={rowIndex}
                        className="d-flex justify-content-between"
                      >
                        {row.map((question, index) => (
                          <div
                            dir={lang === "ar" ? "rtl" : "ltr"}
                            className="col-6 m-4 border border-1 rounded-4 p-0"
                            key={index}
                          >
                            <h3 className="px-3 pt-2">{question?.question}</h3>
                            <div className="px-3 pb-2 d-flex">
                              <p
                                style={{ fontSize: "14px" }}
                                className="m-0 p-0"
                              >
                                {question?.customar?.name
                                  ?.split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() + "***"
                                  )
                                  .join(" ")}
                              </p>
                              &nbsp;-&nbsp;
                              <p
                                style={{ fontSize: "14px" }}
                                className="m-0 p-0"
                              >
                                {question?.updateTime}
                              </p>
                            </div>
                            <div className="rounded-2 bg-gray p-2">
                              <div className="d-flex align-items-center mt-2 mx-2">
                                <img
                                  src={companyData?.companyLogo}
                                  alt={companyData?.companyName}
                                  width={64}
                                  height={64}
                                />
                                <p
                                  style={{ fontSize: "14px" }}
                                  className="mx-2 my-0"
                                >
                                  {companyData?.companyName}
                                </p>
                              </div>
                              <p
                                style={{ fontSize: "14px" }}
                                className="mx-2 my-2"
                              >
                                {question?.answer && question.answer}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  <div className="d-flex justify-content-center product-action">
                    <p
                      className="btn btn-dark addToCartBtn"
                      onClick={
                        questions?.data?.length > 4
                          ? () => navigate(`/product-details/${id}/questions`)
                          : null
                      }
                    >
                      {t("showAllQuestions")}
                    </p>
                  </div>
                </div>
              )}
              <div
                className={`tab-pane fade ${
                  activeTab === "product-reviews-content" ? "show active" : ""
                }`}
                id="product-reviews-content"
                role="tabpanel"
                aria-labelledby="product-tab-reviews"
              >
                <div className="product-reviews-content">
                  <div className="reviews-header justify-content-center">
                    {screen === "large" ? (
                      <>
                        <StarRatings
                          rating={data?.ratingsAverage}
                          starRatedColor="#fe9900"
                          starEmptyColor="lightgray"
                          numberOfStars={5}
                          starDimension="25px"
                          starSpacing=".5px"
                        />
                        <h2>{data?.ratingsAverage.toFixed(2)}</h2>
                      </>
                    ) : (
                      <div className="d-block">
                        <StarRatings
                          rating={data?.ratingsAverage}
                          starRatedColor="#fe9900"
                          starEmptyColor="lightgray"
                          numberOfStars={5}
                          starDimension="25px"
                          starSpacing=".5px"
                        />
                        <h2>{data?.ratingsAverage.toFixed(2)}</h2>
                      </div>
                    )}

                    <span
                      onClick={
                        data?.review?.length > 5
                          ? () => navigate(`/product-details/${id}/reviews`)
                          : null
                      }
                    >
                      {data?.ratingsQuantity} &nbsp;
                      {t("review")}
                    </span>
                  </div>
                  <swiper-container slides-per-view="3">
                    {data?.review &&
                      data?.review?.slice(0, 5).map((review, i) => {
                        return (
                          <swiper-slide key={i}>
                            <div className="review_card">
                              <div className="comment-block">
                                <div className="comment-header">
                                  <div
                                    className="ratings-container"
                                    style={{ display: "block" }}
                                  >
                                    <StarRatings
                                      rating={review?.rating}
                                      starRatedColor="#fe9900"
                                      starEmptyColor="lightgray"
                                      numberOfStars={5}
                                      starDimension="15px"
                                      starSpacing=".5px"
                                    />
                                  </div>

                                  <div className="comment-by">
                                    <span>
                                      {" "}
                                      {`${review?.customar?.name?.substring(
                                        0,
                                        3
                                      )}****` || t("loading")}
                                    </span>

                                    <span>
                                      {review?.updatedAt?.split("T")[0]}
                                    </span>
                                  </div>
                                </div>

                                <div className="comment-content">
                                  <p>{review?.review}</p>
                                </div>
                              </div>
                            </div>
                          </swiper-slide>
                        );
                      })}
                  </swiper-container>
                  <div className="divider"></div>
                </div>
              </div>
            </div>
          </div>

          {/* Related products START (SLIDER) */}
          <div className="products-section pt-0 pb-5">
            {alternateProducts.length > 0 && (
              <>
                <h2 className="section-title  m-b-4">{t("relatedProducts")}</h2>

                <ProductSlider
                  slidesPerView={slides}
                  Array={alternateProducts}
                />
              </>
            )}

            <div className="products-section pt-0">
              <h2 className="section-title  m-b-4">
                {t("bestSellingProducts")}
              </h2>
              <ProductSlider slidesPerView={slides} Array={top10Sold} />
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer autoClose={2000} />
      </>
    );
  }
};

export default ProductDetails;
